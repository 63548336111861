<template>
	<LoadingScreen v-if="store.state.loadingScreen"></LoadingScreen>
	<router-view></router-view>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { store } from './modules/store';
import LoadingScreen from './components/LoadingScreen.vue';

@Options({
	watch: {
        '$route.name': {
            handler: 'routeChange',
        },
    },
	components: {
		LoadingScreen
	}
})

export default class WebApp extends Vue {
	store = store;
    
    mounted() {
        this.routeChange();
    }

	routeChange() {
        if(!store.state.loadingScreen) {
			store.setLoadingScreenActive(String(this.$route.name) !== "Metro Login" && String(this.$route.name) !== "Metro Home" && String(this.$route.name) !== "Metro Terminal");
		}
		document.title = this.$route.meta.title as string;
        if(String(this.$route.name) === "Metro Home") {
            if(this.store.state.playerInfo === null) {
                this.$router.push({ path: '/metro/login' });
            } else {
                this.$router.push({ path: '/metro/terminal' });
            }
        } else if(String(this.$route.name) === "Metro Terminal") {
            if(this.store.state.playerInfo === null) {
                this.$router.push({ path: '/metro/login' });
            }
        } else if(String(this.$route.name) === "Metro Login") {
            if(this.store.state.playerInfo !== null) {
                this.$router.push({ path: '/metro/terminal' });
            }
        }
    }
}
</script>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
	transition: opacity 0.2s linear;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.5);
    float: left;
}

::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.76);
    float: left;
}

</style>