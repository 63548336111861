var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Options, Vue } from "vue-class-component";
import { store } from '../modules/store';
let MetroLogin = class MetroLogin extends Vue {
    constructor() {
        super(...arguments);
        this.store = store;
        this.processingLogin = false;
    }
    loginEvent() {
        this.processingLogin = true;
        setTimeout(() => {
            this.processingLogin = false;
            this.$emit('loginMetro', { username: this.$refs['metro-user'].value, password: this.$refs['metro-pw'].value });
        }, 1000);
    }
};
MetroLogin = __decorate([
    Options({
        props: {
            loggingIn: Boolean,
            failedLogin: Boolean
        },
        emits: [
            'loginMetro',
        ]
    })
], MetroLogin);
export default MetroLogin;
