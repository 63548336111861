<template>
	<div id="menu">
		<div class="nav-link"><a href="./#/metro/">Home</a></div>
		<div class="nav-link"><a href="./#/metro/map">Live Map</a></div>
		<div class="nav-link" v-if="store.state.playerInfo !== null && store.state.playerInfo.AdminLevel > 0"><a href="./#/metro/acp">Admin Control Panel</a></div>
		<div class="nav-link" v-if="store.state.playerInfo !== null"><a href="./#/metro/logout">Log Out</a></div>
	</div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { store } from '../modules/store';

@Options({

})

export default class MetroMenu extends Vue {
	store = store;
}
</script>

<style lang="scss" scoped>
#menu {
	padding-top: 1px;
	width: 100%;
	height: 50px;
	text-align: center;
	font-weight: bold;
	position: relative;
	margin: auto;
	background-color: #EEE;
	border-radius: 25px 25px 0px 0px;
	display: flex;
	align-items: center;
	justify-content: center;

	.nav-link {

		padding: 0 25px;

		a, a:visited {
			color: #3f3f3f;
			text-decoration: none;
			
			&:hover {
				color: #AA2D2D;
			}
		}
	}
}
</style>