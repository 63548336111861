import { createWebHashHistory, createRouter } from 'vue-router';
import Home from '../views/Home.vue';
import Metro from '../views/Metro.vue';
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Home - ImperiumXVII'
        }
    },
    {
        path: '/metro',
        name: 'Metro Home',
        component: Metro,
        meta: {
            title: 'Home - Metropolitan Division - Los Santos Police Department'
        }
    },
    {
        path: '/metro/login',
        name: 'Metro Login',
        component: Metro,
        meta: {
            title: 'Login - Metropolitan Division - Los Santos Police Department'
        }
    },
    {
        path: '/metro/terminal',
        name: 'Metro Terminal',
        component: Metro,
        meta: {
            title: 'Metropolitan Division - Los Santos Police Department'
        }
    },
];
const router = createRouter({
    history: createWebHashHistory(),
    routes,
});
export default router;
