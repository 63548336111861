<template>
	<div class="view-wrapper" v-if="!store.state.loadingScreen">
		<PageBanner></PageBanner>
		<div class="view-content">
			Hello, this is Home.
		</div>
	</div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageBanner from '../components/PageBanner.vue';
import { store } from '../modules/store';

@Options({
	components: {
		PageBanner
	}
})

export default class Home extends Vue {
	store = store;
}
</script>

<style lang="scss" scoped>
.view-content {
    padding: 15px;
}
</style>