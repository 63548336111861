<template>
	<div class="page-banner">
		<div class="navbar">
			<div class="logo">
				<router-link :to="'/'">
					<img src="https://www.imperiumxvii.co.uk/logo.png" />
				</router-link>
			</div>
			<router-link :to="'/'">
				<div class="nav">
					<div class="nav-link">
						Home
					</div>
				</div>
			</router-link>
			<a href="https://community.imperiumxvii.co.uk" target="_blank">
				<div class="nav">
					<div class="nav-link">
						Community
					</div>
				</div>
			</a>
			<a href="https://www.facebook.com" target="_blank">
				<div class="nav">
					<div class="nav-link">
						Facebook
					</div>
				</div>
			</a>
			<a href="https://www.twitter.com" target="_blank">
				<div class="nav">
					<div class="nav-link">
						Twitter
					</div>
				</div>
			</a>
			<router-link :to="'/metro/'" class="end">
				<div class="nav">
					<div class="nav-link">
						Metro
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({

})

export default class PageBanner extends Vue {

}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.page-banner {
	width: 100vw;
	height: 75px;
	background: #2b2b2b;
	z-index: 2;
	font-family: 'Montserrat', sans-serif;

	a {
		text-decoration: none;
	}

	.navbar {
		width: 100%;
		display: flex;
		align-items: center;

		.logo {
			margin: 0 30px;
		}

		.end {
			margin-left: auto;
		}

		:not(.router-link-active) .nav {
			width: 12em;
			text-align: center;
			position: relative;
			transition: background-color 0.2s linear, color 0.2s linear;
			color: white;

			&:hover {
				background-color: #e00;
				color: black;
			}

			.nav-link {
				font-weight: 600;
				line-height: 75px;
			}
		}

		.router-link-active .nav {
			width: 12em;
			text-align: center;
			position: relative;
			transition: background-color 0.2s linear, color 0.2s linear;
			color: color;
			background-color: #e00;
			color: black;

			.nav-link {
				font-weight: 600;
				line-height: 75px;
			}
		}

	}
}

</style>