var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Options, Vue } from "vue-class-component";
import PageBanner from '../components/PageBanner.vue';
import MetroMenu from '../components/MetroMenu.vue';
import MetroLogin from '../components/MetroLogin.vue';
import MetroTerminal from '../components/MetroTerminal.vue';
import { store } from '../modules/store';
import $ from 'jquery';
let Metro = class Metro extends Vue {
    constructor() {
        super(...arguments);
        this.store = store;
        this.loggingIn = false;
        this.failedLogin = false;
    }
    loginMetro(metro) {
        $.post('./metro-verify.php', { 'metro-user': metro.username, 'metro-pw': metro.password }, (data) => {
            if (JSON.parse(data) !== false) {
                this.store.state.playerInfo = JSON.parse(data);
                this.loggingIn = true;
                setTimeout(() => {
                    this.loggingIn = false;
                    this.store.setLoadingScreenActive(false);
                    this.$router.push({ path: '/metro/terminal' });
                }, 1500);
            }
            else {
                this.failedLogin = true;
            }
        });
    }
};
Metro = __decorate([
    Options({
        components: {
            PageBanner,
            MetroLogin,
            MetroMenu,
            MetroTerminal
        }
    })
], Metro);
export default Metro;
