var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Options, Vue } from "vue-class-component";
import { store } from './modules/store';
import LoadingScreen from './components/LoadingScreen.vue';
let WebApp = class WebApp extends Vue {
    constructor() {
        super(...arguments);
        this.store = store;
    }
    mounted() {
        this.routeChange();
    }
    routeChange() {
        if (!store.state.loadingScreen) {
            store.setLoadingScreenActive(String(this.$route.name) !== "Metro Login" && String(this.$route.name) !== "Metro Home" && String(this.$route.name) !== "Metro Terminal");
        }
        document.title = this.$route.meta.title;
        if (String(this.$route.name) === "Metro Home") {
            if (this.store.state.playerInfo === null) {
                this.$router.push({ path: '/metro/login' });
            }
            else {
                this.$router.push({ path: '/metro/terminal' });
            }
        }
        else if (String(this.$route.name) === "Metro Terminal") {
            if (this.store.state.playerInfo === null) {
                this.$router.push({ path: '/metro/login' });
            }
        }
        else if (String(this.$route.name) === "Metro Login") {
            if (this.store.state.playerInfo !== null) {
                this.$router.push({ path: '/metro/terminal' });
            }
        }
    }
};
WebApp = __decorate([
    Options({
        watch: {
            '$route.name': {
                handler: 'routeChange',
            },
        },
        components: {
            LoadingScreen
        }
    })
], WebApp);
export default WebApp;
