<template>
	<span class="logged-in" v-if="loggingIn">You have successfully logged in as {{ store.state.playerInfo.Nickname }} ({{ store.state.playerInfo.Username }})</span>
	<span class="failed" v-if="failedLogin && !loggingIn">Incorrect username or password.</span>
	<div id="login" v-if="!store.state.playerInfo && !loggingIn">
		<h2>ENTER YOUR SECURE DETAILS</h2>
		<form>
			<div><input type="text" name="metro-user" placeholder="Username" ref="metro-user" @keyup.enter="loginEvent" /></div>
			<div><input type="password" name="metro-pw" placeholder="Password" ref="metro-pw" @keyup.enter="loginEvent" /></div>
			<input v-if="!processingLogin" type="button" name="login-button" id="login-button" value="Login" @click="loginEvent" />
			<span class="spinner" v-if="processingLogin"><i class="fad fa-spinner-third fa-spin"></i></span>
		</form>
	</div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { store } from '../modules/store';

@Options({
	props: {
		loggingIn: Boolean,
		failedLogin: Boolean
	},
	emits: [
		'loginMetro',
	]
})

export default class MetroLogin extends Vue {
	store = store;
	loggingIn!: boolean;
	failedLogin!: boolean;
	processingLogin = false;

	loginEvent() {
		this.processingLogin = true;
		setTimeout(() => {
			this.processingLogin = false;
			this.$emit('loginMetro', { username: (this.$refs['metro-user'] as HTMLInputElement).value, password: (this.$refs['metro-pw'] as HTMLInputElement).value });
		}, 1000)
	}
}
</script>

<style lang="scss" scoped>
#login {		
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 92%;

	.spinner {
		display: block;
		text-align: center;
		font-size: 32px;
		color: white;
		margin-top: 10px;
	}

	h2 {
		color: white;
	}

	input {
		width: 40vw;
		height: 5vh;
		padding: 10px;
		margin: 10px;
		background: #EEE;
	}
}

.logged-in {
	position: fixed;
	display: block;
	text-align: center;
	left: 50%;
	width: 800px;
	margin-left: -400px;
	top: 40%;
	font-size: 32px;
	font-weight: 400;
	font-family: 'Roboto';
	color: white;
}

.failed {
	position: fixed;
	display: block;
	text-align: center;
	left: 50%;
	width: 800px;
	margin-left: -400px;
	top: 30%;
	font-size: 32px;
	font-weight: 400;
	font-family: 'Roboto';
	color: red;
}
</style>