import { reactive } from 'vue';
const initialState = {
    loadingScreen: false,
    firstTime: true,
    playerInfo: null,
};
export const store = {
    state: reactive({ ...initialState }),
    setLoadingScreenActive(firstTimeCheck = true) {
        if (this.state.firstTime && firstTimeCheck) {
            this.state.firstTime = false;
            return;
        }
        this.state.loadingScreen = true;
        setTimeout(() => {
            this.state.loadingScreen = false;
        }, 1000);
    },
};
