<template>
	<div class="loading" ref="wrapper">
		<i class="fad fa-spinner-third fa-spin"></i>
	</div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({

})

export default class LoadingScreen extends Vue {
	mounted() {
		(this.$refs.wrapper as HTMLDivElement).style.opacity = '1';
	}
}
</script>

<style lang="scss" scoped>
.loading {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 72px;
	color: white;
	background-color: #3f3f3f;
	opacity: 0;
}
</style>