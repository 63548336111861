<template>
	<div class="view-wrapper" v-if="!store.state.loadingScreen">
		<div class="terminal">
			<div class="title">
				<h1>LOS SANTOS POLICE DEPARTMENT METROPOLITAN DIVISION</h1>
			</div>
			<div id="page">
				<div id="main">
					<MetroMenu />
					<MetroLogin v-if="$route.name === 'Metro Login'" :loggingIn="loggingIn" :failedLogin="failedLogin" @loginMetro="loginMetro" />
					<MetroTerminal v-if="$route.name === 'Metro Terminal'" />
				</div>
				<div class="footer">
					<p id="copy"><b>PROTECTED PROPERTY OF M PLATOON, METROPOLITAN DIVISION, LOS SANTOS POLICE DEPARTMENT</b> - <a href="http://pd.lsgov.us/forum/">LSPD.ONLINE</a></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageBanner from '../components/PageBanner.vue';
import MetroMenu from '../components/MetroMenu.vue';
import MetroLogin from '../components/MetroLogin.vue';
import MetroTerminal from '../components/MetroTerminal.vue';
import { store } from '../modules/store';
import $ from 'jquery';

@Options({
	components: {
		PageBanner,
		MetroLogin,
		MetroMenu,
		MetroTerminal
	}
})

export default class Metro extends Vue {
	store = store;
	loggingIn = false;
	failedLogin = false;

	loginMetro(metro: { username: string, password: string }) {
		$.post('./metro-verify.php', { 'metro-user': metro.username, 'metro-pw': metro.password }, (data) => {
			if(JSON.parse(data) !== false) {
				this.store.state.playerInfo = JSON.parse(data);
				this.loggingIn = true;
				setTimeout(() => {
					this.loggingIn = false;
					this.store.setLoadingScreenActive(false);
					this.$router.push({ path: '/metro/terminal'});
				}, 1500);
			} else {
				this.failedLogin = true;
			}
		});
	}
}
</script>

<style lang="scss" scoped>

.terminal {
	display: flex;
	flex-direction: column;
	align-items: center;
    width: 100%;
	height: 100vh;
	background-color: #3f3f3f;
	font-family: 'Roboto', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

	.title {
		margin: 50px 0 25px 0;
		color: white;
		text-align: center;
	}

	#page {
		width: 80vw;
		margin: 0 auto;

		#main {
			border-radius: 25px 25px 0px 0px;
			background-color: #1f1f1f;
			position: relative;
			height: 80vh;

			&::after {
				content: "";
				background-image: url(https://i.imgur.com/rVnYPfk.png);
				background-repeat: no-repeat;
				background-position: center;
				background-size: 350px 350px;
				opacity: 0.1;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				position: absolute;
				z-index: -1;
			}
		}

		.footer {
			background-color: #EEE;
			position: relative;
			height: 24px;
			margin-top: -1%;
			-webkit-border-bottom-right-radius: 10px;	
			-webkit-border-bottom-left-radius: 10px;	
			-moz-border-radius-bottomright: 10px;	
			-moz-border-radius-bottomleft: 10px;	
			border-bottom-right-radius: 10px;	
			border-bottom-left-radius: 10px;

			#copy {
				padding-top: 4px;
				margin-right: 7px;
				text-align: center;
				font-size: 14px;
				color: #1f1f1f;
				
				a, a:visited {
					color: #1f1f1f;
					font-weight: 500;
				}
			}
		}
	}
}
</style>